import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { Alert, Button, CircularProgress, Link, TextField, Typography } from '@mui/material'
import { IconButton, InputAdornment } from '@mui/material'
import { Visibility, VisibilityOff } from '@mui/icons-material'

import axios from 'axios'

import { useAuth } from './auth'
import ChangePass from './change-pass'

import logo from '../top-bar/examfly-logo-blue.png'
import './index.css'

const CredentialsLogin = (props) => {
  const [username, setUsername] = useState('')
  const [password, setPassword] = useState('')
  const [userAttributes, setUserAttributes] = useState({})
  const [error, setError] = useState(false)
  const [signingIn, setSigningIn] = useState(false)
  const [newPassword, setNewPassword] = useState(false)
  const [passwordReset, setPasswordReset] = useState(false)
  const [showPassword, setShowPassword] = useState(false)
  const { setTokens } = useAuth()
  const navigate = useNavigate()

  useEffect(() => {
    if (newPassword.length > 0) {
      axios({
        url: '/auth/change-initial-pass',
        method: 'post',
        data: {
          newPassword,
          password,
          username,
        },
      })
        .then((r) => {
          const { token, refreshToken } = r.data
          setTokens(token, refreshToken, () => {
            const takeMeTo = localStorage.getItem('postLoginPath') || '/'
            localStorage.setItem('postLoginPath', '/')
            navigate(takeMeTo)
          })
        })
        .catch(console.error)
    }
  }, [newPassword])

  useEffect(() => {
    if (signingIn) {
      axios({
        url: '/auth/login',
        method: 'post',
        data: {
          username,
          password,
        },
      })
        .then((r) => {
          const { token, refreshToken } = r.data
          if (r.data.newPassword) {
            setUserAttributes(r.data.userAttributes)
            setNewPassword(true)
          }
          if (r.data.passwordReset) {
            setPasswordReset(true)
          }
          if (token) {
            setTokens(token, refreshToken, () => navigate(localStorage.getItem('postLoginPath') || '/'))
          }
        })
        .catch((e) => {
          setSigningIn(false)
          setError(e.response.data || e)
        })
    }
  }, [signingIn])

  if (newPassword) {
    return <ChangePass username={username} userAttributes={userAttributes} changePass={(pass) => setNewPassword(pass)} />
  }

  if (passwordReset) {
    return <ChangePass />
  }

  return (
    <div className="login-wrapper">
      <img className="logo" src={logo} alt="Examfly Logo" />
      <form
        style={{ height: '440px' }}
        className="login"
        onSubmit={(e) => {
          e.preventDefault()
          setSigningIn(true)
        }}
      >
        <Typography variant="h1">Login</Typography>
        <TextField
          sx={{ marginTop: '20px', width: '250px' }}
          aria-labelledby="username"
          name="username"
          type="text"
          label="Username"
          variant="outlined"
          onChange={(e) => setUsername(e.target.value.replace(/\s/g, ''))}
          value={username}
        />
        <TextField
          sx={{ marginTop: '20px', width: '250px' }}
          aria-labelledby="password"
          name="password"
          type={showPassword ? 'text' : 'password'}
          label="Password"
          variant="outlined"
          onChange={(e) => setPassword(e.target.value)}
          value={password}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton aria-label="toggle password visibility" onClick={() => setShowPassword(!showPassword)} edge="end">
                  {showPassword ? <VisibilityOff /> : <Visibility />}
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
        {error.message && <Alert severity="error">{error.message}</Alert>}
        <Button disabled={username.length < 3 || password.length < 3 || signingIn} sx={{ width: '200px', marginTop: '20px' }} variant="contained" color="primary" type="submit">
          {signingIn && <CircularProgress size="1em" />}
          Login
        </Button>
        <Link sx={{ width: '200px', marginTop: '20px' }} href="/forgot-password">
          <Button variant="text">Forgot password?</Button>
        </Link>
      </form>
    </div>
  )
}

export default CredentialsLogin
